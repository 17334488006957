import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/home.vue')
  },
  {
    path: '/:slug',
    name: 'userSite',
    component: () => import('@/views/userSite.vue'),
  },
  {
    path: '/:slug/bio',
    name: 'userSiteBio',
    component: () => import('@/views/userSite.vue'),
  },
  {
    path: '/:slug/program',
    name: 'userSiteProgram',
    component: () => import('@/views/userSite.vue'),
  },
  {
    path: '/:slug/news/all/:postslug',
    name: 'post-single',
    component: () => import('@/views/userSite.vue'),
  },
  {
    path: '/:slug/news/all/',
    name: 'posts-all',
    component: () => import('@/views/userSite.vue'),
  },
  {
    path: '/404',
    name: 'not-found',
    component: () => import('@/views/notFound.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  /* eslint-disable no-unused-vars */
  scrollBehavior(to, from, savedPosition) {

    if (to.hash) {
      return {
        selector: to.hash, 
        behavior: 'smooth',
        offset: { x: 0, y: 20 }
      }
    } else {
      return { x: 0, y: 0 };
    }
  }
})

export default router
