import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
//import './registerServiceWorker'

var VueScrollTo = require('vue-scrollto');
Vue.use(VueScrollTo)

Vue.config.productionTip = false

import InputMask from 'vue-input-mask';
Vue.component('input-mask', InputMask)

const moment = require('moment')
require('moment/locale/uk')
Vue.use(require('vue-moment'), {
  moment
});

new Vue({
  router,
  store,
  VueScrollTo,
  render: h => h(App)
}).$mount('#app')
