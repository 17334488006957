<template>
	<div id="app">
		<icons />
		<router-view></router-view>
	</div>
</template>

<script>
import icons from "@/components/icons.vue";
export default {
	data() {
		return {};
	},
	components: {
		icons,
	},
};
</script>

<style lang="scss">
:root {
	--theme-accent: #feda4a;
	--theme-primary: #3eb253;
	--theme-headerBg: transparent;
	--theme-headerStickyBg: #3eb253;
	--theme-headerLinksColor: #fff;
	--theme-primaryButtonColor: #000;
	--theme-linkONWhiteColor: #23a53c;
	--theme-primary-gradient: #000;
	--theme-secondary-gradient: #000;
}
.container {
	@media (max-width: 1024px) {
		width: auto !important;
		max-width: initial !important;
		min-width: 100%;
	}
}
</style>
